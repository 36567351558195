
import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';

const BannerTR = (props) => {
  const [show, setShow] = useState(true);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
     
      <Modal size='xl' show={show} onHide={handleClose}>
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body><a href="https://www.mefar.com/haberler/arcera"><img variant="primary" src={'/esg/images/ArceraDuyuruEN.jpg'} style={{ width: '100%' }} alt='Arcera Duyuru' /></a></Modal.Body>
      </Modal>
    </>
  )
}

export default BannerTR